import React from "react";
import {Box,Button, Grid, InputLabel, Paper, Table,  TableBody,TableCell,TableContainer,TableRow,TextField, makeStyles,} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import CustomSnackbar from "views/components/custom-snackbar";
import {Check, Clear} from "@material-ui/icons";
import {withStyles} from "@material-ui/styles";
import ReactGA from "react-ga";
import FormContainer from "views/containers/form-container/form-container";
import {getEmailVerifyAPI} from "../../../getDetails/manager";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "50%",
        margin: " 0 auto",
        "& > * + *": {
            marginTop: theme.spacing(0.3),
        },
    },
    main: {
        margin: "20px auto 10px auto",
        width: "50%",
        borderRadius: "1rem",
        padding: "1rem",
    },
    rootBox: {
        margin: "0 auto",
    },
    searchBox: {
        width: "100%",
        padding: "10px 14px",
    },
    searchBar: {
        width: "100%",
    },
    resultBox: {
        width: "95%",
        margin: " auto",
        borderRadius: "3px",
    },
    ul: {
        display: "flex",
        listStyle: "none",
        padding: "10px",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        transition: "all .2s ease",
        "&:hover": {
            backgroundColor: " #e7e5e58f",
        },
    },
    EmailVerified: {
        width: "100%",
        margin: "1px auto",
        padding: "4px 8px",
    },
    employeeBox: {
        width: "90%",
        margin: "10px auto",
    },
    paper: {
        padding: "1rem",
        width: "80%",
        margin: "0 auto",
    },
    input: {
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderColor: "#999",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#3f51b5",
            },
        },
    },
    formBox: {
        width: "94%",
        backgroundColor: "#fff",
        justifyContent: "center",
        margin: "0 auto",
    },
    noBorder: {
        border: "none",
    },
    text: {
        textTransform: "capitalize",
    },
    box: {
        padding: "16px",
    },
    heading: {
        fontWeight: 600,
        textTransform: "capitalize",
    },
    tokenInput: {
        width: "100%",
        margin: "10px 0 ",
        borderRadius: 5,
        backgroundColor: "#ffffff66",
    },
    tableContainer: {
        width: "90%",
        margin: "20px auto ",
    },
}));

function EmailVerified() {
    const SubmitButton = withStyles((theme) => ({
        root: {
            color: "#fff",
            padding: "6px 6px 6px 0",
            width: "10rem",
            fontSize: "14px",
            boxShadow:
                " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
            fontWeight: "800",
            borderRadius: "8px",
            letterSpacing: "1px",
            textTransform: "capitalize",
            backgroundColor: "#221c53",
            "&:hover": {
                background: "#fff",
                color: theme.palette.primary.main,
            },
            "& .MuiTouchRipple-root": {
                opacity: 0.2,
                zIndex: -1,
            },
        },
    }))(Button);

    const emailRegex =
        /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+[.])+([a-zA-Z0-9]{2,4})+$/;
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const [errorMesg, setErrorMesg] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [email, setEmail] = useState("");
    const classes = useStyles();

    const handleChange = useCallback((e) => {
        let value = e?.target?.value;
        setEmail(value);
    }, []);

    const formatKey = (str) => {
        if (str) {
            return str.replaceAll("_", " ");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email.match(emailRegex)) {
            setEmailError("");
            setLoader(true);
            getEmailVerifyAPI({email})
                .then((res) => {
                    setData(res.data?.data);
                    // dispatch(getUserDetails());
                })
                .catch((err) => {
                    setErrorMesg(err.response?.data?.message);
                })
                .finally(() => setLoader(false));
        } else {
            setEmailError("Please enter a valid email");
        }
    };

    function columnData(item) {
        if (typeof item == "boolean") {
            if (item) {
                return <Check style={{color: "green"}}/>;
            } else {
                return <Clear style={{color: "red"}}/>;
            }
        } else if (item === null) {
            return <Clear style={{color: "red"}}/>;
        }
        return item;
    }

    const handleClose = () => {
        setErrorMesg(null);
    };

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log(window.location.pathname + window.location.search);
        return () => {
            setData({});
        };
    }, []);

    return (
        <Box component="main">
            {loader && <LinearProgress/>}
            <Grid container xs={12} className={classes.rootBox}>
                <FormContainer title={"Verify your Email"}>
                    <form
                        onSubmit={handleSubmit}
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0rem 1rem",
                        }}
                    >
                        <Grid container spacing={2} style={{margin: 0}}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="ID Number" className={classes.label}>
                                    Email ID
                                </InputLabel>
                                <TextField
                                    required
                                    error={!!emailError}
                                    variant="outlined"
                                    helperText={emailError}
                                    className={classes.tokenInput}
                                    name="email"
                                    size="small"
                                    type={"email"}
                                    value={email}
                                    placeholder={"ex : example@gmail.com"}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid
                                container
                                justifyContent={"center"}
                                spacing={2}
                                style={{margin: 0}}
                            >
                                <Grid item>
                                    <SubmitButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        disabled={!!loader}
                                        style={{marginLeft: "1rem"}}
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </SubmitButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </FormContainer>
                {Object.keys(data).length ? (
                    <TableContainer
                        component={Paper}
                        style={{width: "80%", margin: "40px auto"}}
                    >
                        <Table>
                            <TableBody>
                                {Object.keys(data).map((item, idx) => {
                                    if (
                                        item !== "user_name" &&
                                        item !== "user_mobile" &&
                                        item !== "user_email"
                                    ) {
                                        return (
                                            <TableRow key={idx}>
                                                <TableCell className={classes.heading}>
                                                    {formatKey(item)}
                                                </TableCell>
                                                <TableCell>{columnData(data[item])}</TableCell>
                                            </TableRow>
                                        );
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : null}
            </Grid>

            <CustomSnackbar
                display={errorMesg}
                message={errorMesg}
                dismissNotification={handleClose}
                severity="error"
            />
            {/* <DialogsModal open={openModal} handleClose={handleResponseModal} /> */}
        </Box>
    );
}

export default EmailVerified;
