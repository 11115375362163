import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import CustomSnackbar from "views/components/custom-snackbar";
import referralImg from "../../../assets/referral.jpg";

// const Heading = withStyles((theme) => ({
//     root:
// }))(Box);

const styles = (theme) => ({
  boxContainer: {
    // height: '50vh',
    margin: "30px 30px 30px",
    boxShadow: "none",
  },
  heading: {
    width: "100%",
    textAlign: "center",
    padding: "30px 40px 0px 25px",
    fontSize: "28px",
    fontWeight: 900,
  },

  button: {
    color: "#fff",
    padding: "6px 6px 6px 0",
    width: "10rem",
    fontSize: "14px",
    boxShadow:
      " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    fontWeight: "800",
    borderRadius: "8px",
    letterSpacing: "1px",
    textTransform: "capitalize",
    backgroundColor: "#221c53",
    "&:hover": {
      background: "#fff",
      color: theme.palette.primary.main,
    },
    "& .MuiTouchRipple-root": {
      opacity: 0.2,
      zIndex: -1,
    },
    "&.MuiButton-root.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      boxShadow: "none",
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
});

class LinkedinReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarMessage: "",
    };
    this.gridRef = React.createRef();
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose = () => {
    this.setState({
      snackbarMessage: "",
    });
  };

  render() {
    const { classes, loader, userDetails, errormsg } = this.props;
    const { snackbarMessage } = this.state;

    console.log(userDetails);

    const showSnackbar = (message, severity) => {
      this.setState({
        snackbarMessage: message,
        snackbarSeverity: severity,
      });
    };

    const onCopy = () => {
      navigator.clipboard.writeText(userDetails?.referral_code).then(() => {
        showSnackbar("Referral Code Coppied", "success");
      });
    };

    return (
      <>
        {loader && <LinearProgress />}
        <Box component={Paper} className={classes.boxContainer}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.heading}>
                Get 5 Credits For Every Colleague You Invite To Surereach
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={referralImg}
                style={{
                  maxWidth: 350,
                  objectFit: "contain",
                  padding: "20px 0 40px 0",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ padding: "10px 70px 20px", textAlign: "center" }}
            >

              {/* <Typography style={{ fontSize: "18px", padding: "0 0 10px 0" }}> Earn 5 Bonus Credits with Every Referral! </Typography> */}
              <Typography style={{ fontSize: "18px", padding: "0 0 10px 0" }}>


                For every colleague who joins and installs Surereach on their browser using your Referral Code:
                <Typography
                  style={{ fontWeight: "900", padding: "0 0 0 7px", color: "green" }}
                  component={"span"}
                >
                  {userDetails?.referral_code || "XXXXXXXXXXX"}
                </Typography>
                , both you and your colleague will receive 5 bonus credits.


              </Typography>
              <Typography style={{ fontSize: "18px", padding: "0 0 10px 0" }}>
            <Typography style={{fontWeight:"900" ,fontSize: "18px"}}  component={"span"}>Note:</Typography>Your colleague must have a valid work email to qualify for the bonus credits.</Typography>
            </Grid>
            <Grid
              item
              xs={"12"}
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "0 0 30px 0px",
              }}
            >
              <Button
                disabled={loader}
                className={classes.button}
                style={{ width: "150px", height: "42px" }}
                onClick={onCopy}
              >
                Copy Code
              </Button>
            </Grid>
          </Grid>
        </Box>
        <CustomSnackbar
          display={snackbarMessage}
          message={snackbarMessage}
          dismissNotification={() => {
            this.handleClose();
          }}
          severity={"success"}
        />
        <CustomSnackbar
          display={errormsg}
          message={errormsg}
          dismissNotification={() => {
            this.handleClose();
          }}
          severity={"error"}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loader: state.auth.loader,
  userDetails: state.clientData.userDetails,
  errormsg: state.clientData.errormsg,
});

const mapDispatchToProps = {};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(LinkedinReports);
