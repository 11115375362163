import React from "react";
import {
    Box,
    Button,
    Grid,
    InputLabel,
    TextField,
    makeStyles, MuiThemeProvider, createMuiTheme,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import {withStyles} from "@material-ui/styles";
import {CLEAR_ERROR, GET_EMAIL_PHONE} from "getDetails";
import {getPhoneEmailFromDin} from "getDetails/actions";
import {useCallback, useEffect, useState} from "react";
import ReactGA from "react-ga";
import {useDispatch, useSelector} from "react-redux";
import CustomSnackbar from "views/components/custom-snackbar";
import FormContainer from "views/containers/form-container/form-container";
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "50%",
        margin: " 0 auto",
        "& > * + *": {
            marginTop: theme.spacing(0.3),
        },
    },
    main: {
        margin: "20px auto 10px auto",
        width: "50%",
        borderRadius: "1rem",
        padding: "1rem",
    },
    rootBox: {
        margin: "0 auto",
    },
    searchBox: {
        width: "100%",
        padding: "10px 14px",
    },
    searchBar: {
        width: "100%",
    },
    resultBox: {
        width: "95%",
        margin: " auto",
        borderRadius: "3px",
    },
    ul: {
        display: "flex",
        listStyle: "none",
        padding: "10px",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        transition: "all .2s ease",
        "&:hover": {
            backgroundColor: " #e7e5e58f",
        },
    },
    DinDetail: {
        width: "100%",
        margin: "1px auto",
        padding: "4px 8px",
    },
    employeeBox: {
        width: "90%",
        margin: "10px auto",
    },
    paper: {
        padding: "1rem",
        width: "80%",
        margin: "0 auto",
    },
    input: {
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderColor: "#999",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#3f51b5",
            },
        },
    },
    formBox: {
        width: "94%",
        backgroundColor: "#fff",
        justifyContent: "center",
        margin: "0 auto",
    },
    noBorder: {
        border: "none",
    },
    text: {
        textTransform: "capitalize",
    },
    box: {
        padding: "16px",
    },
    heading: {
        fontWeight: "600",
    },
    tokenInput: {
        width: "100%",
        margin: "10px 0 ",
        borderRadius: 5,
        backgroundColor: "#ffffff66",
    },
    tableContainer: {
        width: "90%",
        margin: "20px auto ",
    },
}));

function DinDetail() {
    const SubmitButton = withStyles((theme) => ({
        root: {
            color: "#fff",
            padding: "6px 6px 6px 0",
            width: "10rem",
            fontSize: "14px",
            boxShadow:
                " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
            fontWeight: "800",
            borderRadius: "8px",
            letterSpacing: "1px",
            textTransform: "capitalize",
            backgroundColor: "#221c53",
            "&:hover": {
                background: "#fff",
                color: theme.palette.primary.main,
            },
            "& .MuiTouchRipple-root": {
                opacity: 0.2,
                zIndex: -1,
            },
        },
    }))(Button);
    const [dinNum, setDinNum] = useState()
    const {loader} = useSelector((state) => state.clientData); // useSelector((state) => state.loaderRed);
    const {emailPhoneDetails, errormsg} = useSelector( (state) => state.planReducer,);
    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MuiPaper: {
                root: {
                    width: "90%", margin: "2rem auto",
                },
            }, MUIDataTableToolbar: {
                actions: {
                    // display: "none",
                },
            }, MuiInputBase: {
                formControl: {
                    "&:hover": {
                        outline: "none",
                    },
                },
            }, MuiTableCell: {
                body: {
                    textAlign: "center",
                },
            }, MUIDataTableHeadCell: {
                data: {
                    fontWeight: "600",
                }, contentWrapper: {
                    justifyContent: "center",
                },
            },
        },
    });

    console.log(errormsg);

    const dispatch = useDispatch();
    const [data, showData] = useState(false);
    const [dinNumber, setdinNumber] = useState("");
    const [dinDetail, setDinDetail] = useState({});
    const columns = [
        "Full Name",
        "Father Name",
        "Date Of Birth",
        "Present Address",
        "Permanent Address",
        "Nationality",
        "Email",
        "Phone",
        "Status", // "DIN Number",
    ];

    const rows = [
        [
            dinDetail?.full_name,
            dinDetail?.father_name,
            dinDetail?.dob,
            dinDetail?.present_address,
            dinDetail?.permanent_address,
            dinDetail?.nationality,
            dinDetail?.email, // dinDetail?.din_number,
            dinDetail?.phone_no,
            dinDetail?.status,
        ],
    ];
    const [openModal, setOpenModal] = useState(false);
    const [errorMesg, setErrorMesg] = useState("");
    const [dinError, showdinError] = useState(false);
    const [checked, setCheck] = useState(false);
    const classes = useStyles();
    let regex = /^[0-9]*$/;

    const handleChange = ((e) => {
        let value = e?.target?.value;

        if (!regex.test(value)) {
            setdinNumber((prevState) => prevState);
            showdinError("DIN Number should only be numeric");
        } else {
            setdinNumber(value);
            setDinNum(value); // Update dinNum state when input is valid
            showdinError(false);
            showData(false);
            setCheck(false);
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!dinNumber && !dinNum) {
            showdinError("Please enter DIN Number");
        } else if (dinNumber.length != 8   && dinNum.length != 8 ) {
            showdinError("DIN length should be 8 characters");
        } else {
            const form = new FormData();
            const data = {
                director_id: dinNumber || dinNum,
            };
            dispatch(getPhoneEmailFromDin(data));
            localStorage.removeItem('din_number');
        }
    };

    const handleClose = () => {
        setErrorMesg(null);
        dispatch({
            type: CLEAR_ERROR,
        });
    };

    useEffect(() => {
        setErrorMesg(errormsg);
    }, [errormsg]);

    useEffect(() => {
        if (emailPhoneDetails) {
            emailPhoneDetails.present_address =
                emailPhoneDetails?.present_address?.replaceAll("$", ", ");
        }
        setDinDetail(emailPhoneDetails);
        showData(emailPhoneDetails);
    }, [emailPhoneDetails]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log(window.location.pathname + window.location.search);
        return () => {
            dispatch({type: GET_EMAIL_PHONE});
            setDinDetail({});
        };
    }, []);

    useEffect(() => {
        const storedDinNumber = localStorage.getItem('din_number');
        setDinNum(storedDinNumber);
        return()=>{
            localStorage.removeItem('din_number');
        }
    }, []);

    return (
        <Box component="main">
            {loader && <LinearProgress/>}
            <Grid container xs={12} className={classes.rootBox}>
                <FormContainer title={"DIN Detail"}>
                    <form
                        onSubmit={handleSubmit}
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0rem 1rem",
                        }}
                    >
                        <Grid container spacing={2} style={{margin: 0}}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="ID Number" className={classes.label}>
                                    DIN Number
                                </InputLabel>
                                <TextField
                                    required
                                    error={!!dinError}
                                    variant="outlined"
                                    helperText={dinError}
                                    className={classes.tokenInput}
                                    name="dinNumber"
                                    size="small"
                                    value={dinNumber || dinNum}
                                    placeholder={"ex : 876XXX"}
                                    onChange={handleChange}
                                    inputProps={{
                                        maxLength: 8,
                                    }}
                                />
                            </Grid>
                            <Grid
                                container
                                justifyContent={"center"}
                                spacing={2}
                                style={{margin: 0}}
                            >
                                <Grid item>
                                    <SubmitButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        disabled={!!loader}
                                        style={{marginLeft: "1rem"}}
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </SubmitButton>
                                </Grid>
                                {/* <Grid item>
                                    <Button
                                        variant={"contained"} color={"primary"}
                                        // disabled={Object.keys(jsonResponseBody).length === 0 || !loader}
                                        onClick={() => setOpenModal(true)}>
                                        Show JSON Response
                                    </Button>
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </form>
                </FormContainer>
            </Grid>
            {data && (<>
                {dinDetail ? (// <ResultContainer title={"Company Detail"}>
                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={"DIN Details"}
                            data={rows}
                            columns={columns}
                            options={{selectableRows: false}}
                        ></MUIDataTable>
                    </MuiThemeProvider>
                    // </ResultContainer>
                ) : null}
            </>)}

            <CustomSnackbar
                display={errorMesg}
                message={errorMesg}
                dismissNotification={handleClose}
                severity="error"
            />
            {/* <DialogsModal open={openModal} handleClose={handleResponseModal} /> */}
        </Box>
    );
}

export default DinDetail;
